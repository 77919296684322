import React from 'react';
import FooterComponent from '../component/footerComponent';
import { graphql } from 'gatsby';
import CompanyHomeComponent from '../component/Company/companyHomeComponent';

import Layout from '../component/layout';
import BlogArticleComponent from '../component/SuccessStories/blogArticleComponent';
const Blog = props => {
  const {
    data: {
      allWpPost: { nodes },
      wpPage: {
        seo,
        homePageHeaderSection: {
          homeHeaderTitle,
          homeHeaderDescription,
          homeHeaderImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: blogHomeImage },
            },
          },
        },
      },
    },
  } = props;
  return (
    <Layout title={seo.title} seo={seo}>
      <div className='blog-section'>
        <>
          <CompanyHomeComponent
            companyHomeTitle={homeHeaderTitle}
            companyHomeDescription={homeHeaderDescription}
            companyHomeImage={blogHomeImage}
          />
        </>
        {nodes.map((item, i) => {
          const isEven = i % 2 === 0;
          return (
            <BlogArticleComponent
              key={i}
              blogArticleLink={`/${item.slug}`}
              blogArticleTitle={item.blogpostgroup.blogCardTitle}
              blogArticleByWho={item.blogpostgroup.blogCardAuthor}
              blogArticleDescription={item.blogpostgroup.blogCardDescription}
              blogArticleButton={'Continue reading'}
              blogImage={
                item.blogpostgroup.blogCardImage?.localFile?.childImageSharp
                  ?.gatsbyImageData
              }
              reverse={isEven ? true : false}
            />
          );
        })}
      </div>
      <FooterComponent />
    </Layout>
  );
};
export const query = graphql`
  {
    allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { slug: { eq: "blog-post" } } } }
      }
    ) {
      nodes {
        blogpostgroup {
          blogCardTitle
          blogCardDescription
          blogCardAuthor
          blogCardImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: DOMINANT_COLOR)
              }
            }
          }
        }
        slug
        title
      }
    }
    wpPage(slug: { eq: "blog-page" }) {
      seo {
        title
        metaDesc
      }
      homePageHeaderSection {
        homeHeaderTitle
        homeHeaderDescription
        homeHeaderImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
export default Blog;
