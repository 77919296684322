import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { Link } from 'gatsby';

// This file is used at : [ blog.js ]

const BlogArticleComponent = (props) => {
  const {
    blogArticleTitle,
    blogArticleByWho,
    blogArticleDescription,
    blogArticleButton,
    isEnd,
    blogImage,
    blogArticleLink,
    reverse,
  } = props;
  return (
    <div
      className={`blog-article-section ${
        isEnd ? 'blog-article-section-text' : ''
      }`}
    >
      <div className='full-container'>
        <div
          className={`d-flex blog-article-section-container ${
            reverse ? 'flex-row-reverse' : ''
          }`}
        >
          <div>
            <GatsbyImage
              alt='blogImage'
              image={blogImage}
              className='blog-article-section-image'
            />
          </div>
          <div className=' blog-article-section-col'>
            <h2 className='blog-article-section-title'>{blogArticleTitle}</h2>
            <p className='blog-article-section-subtitle'>{blogArticleByWho}</p>
            <p className='blog-article-section-description'>
              {blogArticleDescription}
            </p>
            <div className='blog-article-section-container-btn'>
              <Link
                className='blog-article-section-button'
                to={blogArticleLink}
              >
                {blogArticleButton}
                <span className='blog-article-section-button-sx'>{'>'}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BlogArticleComponent;
