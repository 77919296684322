import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

// This file is used at : [ blog.js ]

const CompanyHomeComponent = props => {
  const { companyHomeTitle, companyHomeDescription, companyHomeImage } = props;
  return (
    <div
      className=''
      style={{
        marginBottom: '100px',
      }}
    >
      <div className='aboutus-home-section-container full-container'>
        <div className='row align-items-center'>
          <div className='col-xl-6 order-2 order-xl-1'>
            <div className='aboutus-home-section-container-text'>
              <p
                className='ourStaff-home-section-container-text-title'
                dangerouslySetInnerHTML={{ __html: companyHomeTitle }}
              />
              <p
                className='ourStaff-home-section-container-text-description'
                dangerouslySetInnerHTML={{ __html: companyHomeDescription }}
              />
            </div>
          </div>
          <div className='aboutus-home-section-container-image order-1 order-xl-2 col-xl-6 d-flex justify-content-xl-start justify-content-center justify-content-xxl-center'>
            <div className='image-shadow' />
            <div className='gradient-1' />
            <div className='gradient-2' />
            <GatsbyImage
              image={companyHomeImage}
              className='aboutus-home-section-container-image-img'
              alt='About us image'
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CompanyHomeComponent;
